@import "../../../style/index.scss";

.menu-laterale {
	background-color: #fafbfc !important;
	position: fixed;
	display: flex;
	align-content: center;
	flex-direction: column;
	justify-content: flex-start;
	flex-wrap: nowrap;
	align-items: flex-start;
	overflow-y: auto;
	height: calc(100vh - 70px);
	width: 298px;
	top: 70px;
	left: 0;
	box-shadow: 7px -6px 5px -8px #5f6368;
	animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	z-index: 1200;
	.menu {
		padding: 0;
		margin-top: 10px;
		top: 0;
		width: 100%;
		height: auto;
		.riga {
			list-style-type: none;
			padding: 5px 10px;
			font-weight: 600;
			color: #1a4f8e !important;
			font-size: 16px;
			width: 100%;
			margin: 0;
			span {
				display: flex;
				flex-direction: column;
				height: 27px;
				&:hover {
					background-color: $light-gray-corepla-second;
				}
				& a.voce-menu {
					position: relative;
					bottom: 6px;
				}
			}
			&.hidden {
				display: none;
			}
			.icona {
				margin: 7px 3px;
			}
			.voce-menu {
				line-height: 20px;
				color: $primary;
				margin-left: 1px;
				cursor: pointer;
				text-decoration: none;
				margin: -5px 0 10px 5px;
				svg {
					vertical-align: text-bottom;
					margin: 2px;
				}
			}
			.voce-menu-sub {
				color: $primary;
				cursor: pointer;
				border: none;
				&:hover {
					font-weight: 600;
					text-decoration: underline;
				}
			}
			ul {
				list-style: none;
				margin: -5px 0 -15px 16px;
				padding: 5px 10px;
				li {
					font-size: 13px;
					font-weight: normal;
					list-style: none;
					margin: 0;
					&:hover {
						font-size: 14px;
					}
				}
			}
		}
	}
}
