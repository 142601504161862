@import "node_modules/bootstrap/scss/bootstrap";

$primary: #1a4f8e;
$secondary: #00a2de;
$tertiary: #41ccff;
$gray-corepla: #495e77;
$light-gray-corepla: #f5f5f5;
$light-gray-corepla-second: #f8f8f8;
$light_secondary: #7bc0da;

@keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-1000px);
		transform: translateX(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slide-out-left {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-1000px);
		transform: translateX(-1000px);
		opacity: 0;
	}
}

@-webkit-keyframes slide-out-bottom {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
}
@keyframes slide-out-bottom {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
}

*,
:after,
:before {
	box-sizing: border-box;
}
button:focus {
	outline: 0 !important;
	box-shadow: none !important;
}
body {
	background: #f3f3f3;
	font-size: 13px;
}

h4,
h5 {
	font-size: 20px;
	line-height: 1.33;
	margin: 0;
	padding: 0px;
}
button {
	background: $primary;
	color: #fff;
}
svg {
	vertical-align: 0;
}
.table {
	margin-top: 20px;
}
table td {
	vertical-align: middle !important;
	input[type="checkbox"] {
		margin: 0 5px;
	}
}

.tbody-controlli-processo {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	tr {
		flex-basis: 95%;
		display: flex;
	}
	td:nth-child(2n) {
		flex: 1;
		text-align: right;
	}
}

.tooltip {
	font-size: 0.8rem;
}

.numero-card {
	position: inherit;
	top: 0;
	left: 0;
	margin-bottom: 10px;
	width: 20px;
	height: 20px;
	padding: 1px 0 3px 0;
	text-align: center;
	color: $primary;
	font-size: 0.7rem;
	&.trasferimenti {
		border: 1px solid $primary;
		border-radius: 100%;
	}
	&.uscite {
		border: 1px solid $primary;
		border-radius: 100%;
	}
}

.note-tabella-wrapper {
	width: 100%;
	.note-tabella {
		width: 100%;
		border-collapse: separate;
		margin: 25px 0;
		border-radius: 5px 5px 0 0;
		text-align: center;
		thead tr {
			background-color: $primary;
			color: #ffffff;
			text-align: center;
			font-weight: bold;
		}
		th,
		td {
			padding: 10px 15px;
			&:last-child {
				// border-right: 1px solid $primary !important;
			}
		}
		tbody tr {
			border-bottom: 1px solid #cccaca;
		}
		tbody tr:nth-of-type(even) {
			background-color: #e1e1e1;
		}
	}
}

.tabella-impianti-wrapper {
	width: 100%;
	.tabella-impianti {
		width: 100%;
		border-collapse: separate;
		margin: 25px 0;
		font-size: 0.9em;
		border-radius: 5px;
		text-align: center;
		thead tr {
			background-color: $primary;
			color: #ffffff;
			font-weight: bold;
		}
		tbody tr {
			border-bottom: 1px solid #dad7d7;
		}
		tbody tr:nth-of-type(odd) {
			background-color: #ffffff;
		}
		tbody tr:nth-of-type(even) {
			background-color: #f0f0f0;
		}
		th,
		td {
			padding: 5px 10px;
			button {
				border-radius: 5px;
				font-size: 0.8rem;
				padding: 3px 7px;
			}
		}
	}
}

.tabella-parametri-wrapper {
	width: 100%;
	display: inline-flex;
	flex-wrap: wrap;
	max-width: -webkit-fill-available;
	.tabella-parametri {
		width: 100%;
		border-collapse: separate;
		margin: 25px 0;
		font-size: 0.9em;
		border-radius: 5px;
		text-align: center;
		thead tr {
			background-color: $primary;
			color: #ffffff;
			font-weight: bold;
		}
		tbody tr {
			border-bottom: 1px solid #dad7d7;
		}
		tbody tr:nth-of-type(odd) {
			background-color: #ffffff;
		}
		tbody tr:nth-of-type(even) {
			background-color: #f0f0f0;
		}
		th,
		td {
			padding: 5px 10px;
			button {
				border-radius: 5px;
				font-size: 0.8rem;
				padding: 3px 7px;
			}
		}
		td:nth-child(1) {
			width: 30% !important;
			height: 20% !important;
		}
	}
}

.sticky-top {
	top: 60px !important;
}
#header {
	background-color: #004f92;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
	min-height: 70px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: fixed;
	z-index: 11;
	width: 100%;
	left: 0;
	top: 0;
	padding: 0;
	.h-inner {
		list-style: none;
		padding: 10px;
		margin-bottom: 0;
		position: relative;
	}

	.h-inner > li:not(.pull-right) {
		float: left;
	}
	.pull-right {
		float: right !important;
	}
	.site-tagline {
		padding: 5px 5px;
		font-weight: 500;
		font-size: 18px;
	}

	.hi-menu {
		list-style: none;
		padding: 5px 0;
	}
	.hi-menu > li {
		display: inline-block;
		margin: 0 1px;
		vertical-align: top;
		min-width: 50px;
	}
	.hi-menu > li > a {
		color: #fff;
		display: block;
		text-align: center;
		z-index: 1;
		position: relative;
		-webkit-transition: background-color;
		-o-transition: background-color;
		transition: background-color;
		-webkit-transition-duration: 250ms;
		transition-duration: 250ms;
		border-radius: 2px;
	}
}

.main {
	padding-top: 60px;
}
.overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: #adadad;
	opacity: 0.7;
	z-index: 20;
}

.btn {
	border-radius: 0;
	&.freccia {
		float: left;
		padding: 5px 7px;
		margin: 5px 2px;
		color: $primary;
		font-size: large;
	}
}
.btn-primary {
	background: $primary;
}
.btn-secondary {
	background: $light-gray-corepla;
	color: #000;
}
.btn-tertiary {
	background: $tertiary;
}
.btn-light {
	color: $primary;
	background: #f1e96e;
	&:hover {
		color: #000;
		background: #fcf156;
	}
}

.riga-interruzione {
	display: block;
	width: auto;
	height: 20px;
	padding: 2px;
	background-color: #d5d5d5a2;
}

.react-datepicker-wrapper {
	display: block !important;
}

.cor-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.cor-container-fluid {
	width: 100%;
	padding: 20px;
}

.cor-card {
	background: #fff;
	border-radius: 0px;
	box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
	padding: 0;
	margin: 20px 0;
	.cor-card-header {
		padding: 15px;
		background: $light-gray-corepla;
	}
	.cor-card-body {
		padding: 15px;
		label {
			font-weight: bold;
			margin: 0;
		}
	}
	.cor-card-footer {
		padding: 10px;
		text-align: right;
	}
}

.side-view-show {
	visibility: visible;
	width: 800px;
}
.side-view-overlay {
	background-color: hsla(0, 0%, 66.3%, 0.3);
	position: fixed;
	width: 100%;
	height: calc(100vh - 55px);
	top: 0;
	left: 0;
	-webkit-transition: opacity 0.5s, visibility 0.5s;
	transition: opacity 0.5s, visibility 0.5s;
	visibility: hidden;
	opacity: 0;
	z-index: 100;
}
.side-view-overlay-show {
	visibility: visible;
	opacity: 0.3;
	height: 100vh;
	background-color: rgba(0, 0, 0, 1);
}

.side-view {
	background-color: white;
	position: fixed;
	overflow: auto;
	padding: 20px;
	height: calc(100vh);
	width: 0px;
	top: 0;
	right: 0;
	-webkit-box-shadow: -4px 0px 6px -2px rgba(89, 89, 89, 1);
	-moz-box-shadow: -4px 0px 6px -2px rgba(89, 89, 89, 1);
	box-shadow: -4px 0px 6px -2px rgba(89, 89, 89, 1);
	transition: width 0.5s, visibility 0.5s, box-shadow 0.5s;
	visibility: hidden;
	z-index: 101;
	h4 {
		margin-bottom: 20px;
	}
	label {
		font-weight: bold;
	}
	input[type="checkbox"] {
		-ms-transform: scale(1.3); /* IE */
		-moz-transform: scale(1.3); /* FF */
		-webkit-transform: scale(1.3); /* Safari and Chrome */
		-o-transform: scale(1.3); /* Opera */
		transform: scale(1.3);
		padding: 10px;
		margin-top: 3px;
	}
	.edit-panel {
		background: #eaeaea;
		padding: 20px;
		h6 {
			border-bottom: 1px #dadada solid;
			padding: 10px 0 10px 0;
			margin: 10px 0;
			font-weight: bold;
			color: #1a4f8e;
		}
	}
}

.side-view-show {
	visibility: visible;
	width: 800px;
}

.table-input {
	margin: 0 5px;
	height: auto;
	display: inline-block;
	width: 50%;
	font-size: 12px;
	text-align: right;
	padding: 2px;
}

.dichiarazioneNavigator {
	margin: 15px 0;
	right: 0;
	display: flex;

	.bloccati {
		background: #e3e3e3e8;
		cursor: not-allowed !important;
		& button {
			color: #9e9fa1 !important;
			cursor: not-allowed !important;
		}
	}

	.sel-button {
		border: 1px solid #1a4f8e;
	}
	.tab-mese-APPROVATA {
		background: #acf1b2;
	}
	.tab-mese-VERIFICATA {
		background: #d9f2cb;
	}

	.tab-mese-NUOVA {
		background: #bdd6ff;
	}

	.tab-mese-DA_VERIFICARE {
		background: #ffd1c3;
	}

	.tab-mese-REVISIONE_CDR {
		background: #efffc3d8;
	}

	button {
		font-size: 12px;
		padding: 5px;
	}

	div {
		background: #f5f5f5;
		padding: 10px 15px;
		margin: 2px 2px 2px 0;
		&:hover {
			background: #dedede;
		}
	}
}

.dich-section {
	margin-top: 10px;
	h6 {
		border-bottom: #dedede 1px solid;
		padding: 10px 0;
		font-weight: bold;
	}
}

.dich-summary,
.gestione-impianti-filter,
.gestione-cdr-filter {
	font-size: 16px;
}

.dich-controlli-table {
	border: none;
	td {
		border: none;
		padding: 5px 0;
		font-size: 12px;
		&.pallini-processo {
			width: 70% !important;
			float: right !important;
		}
	}
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #efefef9c;
	opacity: 1;
}

.form-control {
	input[type="number"] {
		text-align: right !important;
	}
}

.selection-panel {
	display: none;
	select {
		width: 80%;
		margin-right: 3px;
		padding: 0 5px;
		display: inline-block;
		height: 30px;
	}
	a {
		margin-left: 8px;
		padding: 0;
	}
}
.btn-panel {
	display: inline-flex;
	flex: 1;
	button {
		margin-top: 15px;
		margin-right: 10px;
	}
}

.btn-link {
	margin: 0;
	padding: 0;
}

.fixed-header {
	background: #fff;
	border-bottom: 1px solid #dadada;
	margin: 0 -15px;
	padding: 15px;
	z-index: 10;
}

.internal-box {
	border: none;
	padding: 10px;
	margin-bottom: 15px;
	background: #ffffff;
	box-shadow: 2px 2px 10px 3px rgba(202, 202, 202, 0.918),
		2px 2px 10px 3px rgba(202, 202, 202, 0.918);
	input,
	select {
		padding: 5px;
		font-size: 12px;
		height: inherit;
	}
	.box-footer {
		text-align: right;
	}
}

.hidden {
	display: none;
}

.print {
	a {
		color: white;
	}
}

.toast-bar {
	visibility: hidden;
	min-width: 250px;
	margin-left: -125px;
	background-color: #333;
	color: #fff;
	text-align: center;
	border-radius: 2px;
	padding: 16px;
	position: fixed;
	z-index: 1;
	left: 50%;
	bottom: 30px;
	font-size: 17px;

	&.show {
		visibility: visible;
		-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
		animation: fadein 0.5s, fadeout 0.5s 2.5s;
		&.success {
			background-color: #2db951;
		}
		&.danger {
			background-color: #ab2215;
		}
	}

	@-webkit-keyframes fadein {
		from {
			bottom: 0;
			opacity: 0;
		}
		to {
			bottom: 30px;
			opacity: 1;
		}
	}

	@keyframes fadein {
		from {
			bottom: 0;
			opacity: 0;
		}
		to {
			bottom: 30px;
			opacity: 1;
		}
	}

	@-webkit-keyframes fadeout {
		from {
			bottom: 30px;
			opacity: 1;
		}
		to {
			bottom: 0;
			opacity: 0;
		}
	}

	@keyframes fadeout {
		from {
			bottom: 30px;
			opacity: 1;
		}
		to {
			bottom: 0;
			opacity: 0;
		}
	}
}

// Extrasmall devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Small devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Medium devices (small screen desktops, 1024px and up)
@media (min-width: 1024px) {
}

// Large devices (desktops, 1200px and up)
@media (min-width: 1200px) {
}
